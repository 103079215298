
.contain_box {
    height: 100%;

    .head_title {
        height: 80px;
        display: flex;
        justify-content: space-between;

        .left_title {
            margin-left: 40px;
        }

        .right_title {
            margin-right: 40px;
            display: flex;

            .divider {
                background-color: #DCDFE6;
                display: inline-block;
                width: 1px;
                height: 3em;
                margin-top: 18px;
                margin-right: 8px;
                margin-left: 8px;
                vertical-align: middle;
                position: relative;
            }
        }
    }

    .middle_box {
        padding-top: 40px;
    }

    .main_box {
        padding-top: 20px;
        margin-left: 40px;
    }
}
