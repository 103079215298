
.contain_box {
    height: 100%;

    .head_title {
        height: 80px;
        display: flex;
        justify-content: space-between;

        .left_title {
            margin-left: 40px;
        }
    }

    .divider {
        padding: 5px 20px 0 20px;
    }

    .main_box {
        padding-top: 20px;
        margin-left: 40px;

        .left-item-fan {
            margin-bottom: 20px;

            .item-half-sub {
                font-size: 16px;
                line-height: 24px;
            }

            .left-item-content {
                background: #fff;
                margin-top: 15px;
                padding: 15px;

                .fan-tab {
                    text-align: right;
                    padding-right: 30px;
                    color: #999;
                    line-height: 1;

                    .item {
                        display: inline-block;
                        padding: 0 10px;
                        border-right: 1px solid #999;
                        cursor: pointer;

                        &:hover.item {
                            color: #20C997;
                        }

                        &:current {
                            color: #20C997;
                        }

                        &:last-of-type {
                            border-right: 0;
                            padding: 0 0 0 10px;
                        }
                    }
                }
            }
        }
    }

    .current {
        color: #20C997;
    }
}
