
.contain_box {
    height: 100%;

    .head_title {
        height: 80px;
        display: flex;
        justify-content: space-between;

        .left_title {
            margin-left: 40px;
        }
    }

    .divider {
        padding: 5px 20px 0 20px;
    }

    .main_box {
        padding-top: 20px;
        margin-left: 40px;
    }
}
