
.favorite {
    height: 100%;
    display: flex;

    .leftBox {
        width: 80%;
        display: flex;
        flex-direction: column;

        .head_box {
            width: 100%;
            height: 160px;
            background-color: #FFFFFF;

            .head_title {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .left_title {
                    display: flex;

                    .pic_box {
                        padding: 20px;
                    }

                    .data_box {
                        padding: 10px 0;

                        .like {
                            display: flex;
                            font-size: 14px;
                            font-weight: 400;

                            span {
                                display: flex;
                                justify-content: start;
                                align-items: center;
                                margin-right: 15px;
                            }
                        }

                        .hotWord {
                            /*.el-tag {*/
                            /*    background: #f4f4f4;*/
                            /*    border-radius: 12px;*/
                            /*    border: 1px solid #f4f4f4;*/
                            /*    height: 24px;*/
                            /*    line-height: 24px;*/
                            /*    font-size: 12px;*/
                            /*    font-family: Microsoft YaHei;*/
                            /*    font-weight: 400;*/
                            /*    color: #333;*/
                            /*    padding: 0 15px;*/
                            /*    margin-left: 5px;*/
                            /*}*/
                        }
                    }
                }

                .right_button {
                    padding: 20px;
                }
            }
        }

        .tab_box {
            display: flex;
            flex: 1;
            height: 1%;
            margin-top: 20px;

            ::v-deep .el-tabs {
                height: 100%;
                width: 100%;
                box-shadow: none;
                border: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                background: transparent;
            }

            ::v-deep .el-tabs__header {
                border-bottom: none;
                margin: 0;
            }

            ::v-deep .el-tabs__content {
                height: 1%;
                flex: 1;
                background: #fff;
                padding-top: 20px;
                min-height: 600px;
            }

            ::v-deep .el-tabs__header .is-active {
                background: #fff;
                font-size: 16px;
                font-weight: 400;
                color: #0824ce;
                border-radius: 6px 6px 0 0;
            }

            ::v-deep .el-tabs__item {
                border: none;
                height: 52px;
                line-height: 52px;
            }

            ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                border: none;
            }

            ::v-deep .el-tab-pane {
                height: 100%;
            }
        }
    }
}

